import { providers } from 'ethers';
import { Network, BaseProvider } from '@ethersproject/providers';
import FallbackProvider from '@adrastia-oracle/ethers-fallback-provider/lib/FallbackProvider';
import {
  Arbitrum,
  Avalanche,
  BSC,
  Base,
  Boba,
  ChainId,
  Gnosis,
  Linea,
  Mainnet,
  Moonbeam,
  Optimism,
  Polygon,
} from '@usedapp/core';
import StaticWebsocketProvider from './providers/StaticWebsocketProvider';
import {
  Blast,
  Celo,
  Corn,
  Filecoin,
  Gravity,
  Lisk,
  MantaPacific,
  Mantle,
  Metis,
  Mode,
  PolygonZkEVM,
  Rootstock,
  Scroll,
  Sei,
  Taiko,
  ZkSync,
} from './custom-networks';

const StaticJsonRpcProvider = providers.StaticJsonRpcProvider;

const NETWORK_ARBITRUM: Network = {
  name: 'Arbitrum One',
  chainId: Arbitrum.chainId,
};

const NETWORK_OPTIMISM: Network = {
  name: 'Optimism',
  chainId: Optimism.chainId,
};

const NETWORK_POLYGON: Network = {
  name: 'Polygon',
  chainId: Polygon.chainId,
};

const NETWORK_ETHEREUM: Network = {
  name: 'Ethereum',
  chainId: Mainnet.chainId,
};

const NETWORK_MODE: Network = {
  name: 'Mode',
  chainId: Mode.chainId,
};

const NETWORK_BSC: Network = {
  name: 'BNB Smart Chain',
  chainId: BSC.chainId,
};

const NETWORK_POLYGONZKEVM: Network = {
  name: 'Polygon zkEVM',
  chainId: PolygonZkEVM.chainId,
};

const NETWORK_MOONBEAM: Network = {
  name: 'Moonbeam',
  chainId: Moonbeam.chainId,
};

const NETWORK_BOBAETHEREUM: Network = {
  name: 'Boba Network',
  chainId: Boba.chainId,
};

const NETWORK_ZKSYNCERA: Network = {
  name: 'ZkSync Era',
  chainId: ZkSync.chainId,
};

const NETWORK_FILECOIN: Network = {
  name: 'Filecoin',
  chainId: Filecoin.chainId,
};

const NETWORK_ROOTSTOCK: Network = {
  name: 'Rootstock',
  chainId: Rootstock.chainId,
};

const NETWORK_SCROLL: Network = {
  name: 'Scroll',
  chainId: Scroll.chainId,
};

const NETWORK_BASE: Network = {
  name: 'Base',
  chainId: Base.chainId,
};

const NETWORK_BLAST: Network = {
  name: 'Blast',
  chainId: Blast.chainId,
};

const NETWORK_LINEA: Network = {
  name: 'Linea',
  chainId: Linea.chainId,
};

const NETWORK_GNOSIS: Network = {
  name: 'Gnosis',
  chainId: Gnosis.chainId,
};

const NETWORK_METIS: Network = {
  name: 'Metis',
  chainId: Metis.chainId,
};

const NETWORK_AVALANCHE: Network = {
  name: 'Avalanche',
  chainId: Avalanche.chainId,
};

const NETWORK_MANTAPACIFIC: Network = {
  name: 'Manta Pacific',
  chainId: MantaPacific.chainId,
};

const NETWORK_TAIKO: Network = {
  name: 'Taiko',
  chainId: Taiko.chainId,
};

const NETWORK_ARBITRUM_SEPOLIA: Network = {
  name: 'Arbitrum Sepolia',
  chainId: 421614,
};

const NETWORK_SEI: Network = {
  name: 'Sei',
  chainId: Sei.chainId,
};

const NETWORK_MANTLE: Network = {
  name: 'Mantle',
  chainId: Mantle.chainId,
};

const NETWORK_LISK: Network = {
  name: 'Lisk',
  chainId: Lisk.chainId,
};

const NETWORK_GRAVITY: Network = {
  name: 'Gravity',
  chainId: Gravity.chainId,
};

const NETWORK_CORN: Network = {
  name: 'Corn',
  chainId: Corn.chainId,
};

const NETWORK_CELO: Network = {
  name: 'Celo',
  chainId: Celo.chainId,
};

const NETWORKS = [
  NETWORK_ARBITRUM,
  NETWORK_OPTIMISM,
  NETWORK_POLYGON,
  NETWORK_ETHEREUM,
  NETWORK_MODE,
  NETWORK_BSC,
  NETWORK_POLYGONZKEVM,
  NETWORK_MOONBEAM,
  NETWORK_BOBAETHEREUM,
  NETWORK_ZKSYNCERA,
  NETWORK_FILECOIN,
  NETWORK_ROOTSTOCK,
  NETWORK_SCROLL,
  NETWORK_BASE,
  NETWORK_BLAST,
  NETWORK_LINEA,
  NETWORK_GNOSIS,
  NETWORK_METIS,
  NETWORK_AVALANCHE,
  NETWORK_MANTAPACIFIC,
  NETWORK_TAIKO,
  NETWORK_SEI,
  NETWORK_MANTLE,
  NETWORK_LISK,
  NETWORK_ARBITRUM_SEPOLIA,
  NETWORK_GRAVITY,
  NETWORK_CORN,
  NETWORK_CELO,
];

export function getWebsocketUrl(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): string | undefined {
  // Disabled for now to reduce connection overhead

  return undefined;
}

export function getPrimaryJsonRpcUrl(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): string | undefined {
  //if (which === 'client') {
  switch (chainId) {
    case ChainId.Arbitrum:
      return 'https://arb-mainnet.g.alchemy.com/v2/_GSTwJ4hTGYgtw0g7d2FJ0-4TOEhBa3Q';
    case ChainId.Optimism:
      return 'https://opt-mainnet.g.alchemy.com/v2/_GSTwJ4hTGYgtw0g7d2FJ0-4TOEhBa3Q';
    case ChainId.Polygon:
      return 'https://polygon-mainnet.g.alchemy.com/v2/_GSTwJ4hTGYgtw0g7d2FJ0-4TOEhBa3Q';
    case ChainId.Mainnet:
      return 'https://eth-mainnet.g.alchemy.com/v2/_GSTwJ4hTGYgtw0g7d2FJ0-4TOEhBa3Q';
    case ChainId.Base:
      return 'https://base-mainnet.g.alchemy.com/v2/_GSTwJ4hTGYgtw0g7d2FJ0-4TOEhBa3Q';
  }
  /*} else if (which === 'server') {
    switch (chainId) {
      case ChainId.Arbitrum:
        return env.WORKER_ALCHEMY_HTTP_ARBITRUMONE;
      case ChainId.Optimism:
        return env.WORKER_ALCHEMY_HTTP_OPTIMISM;
      case ChainId.Polygon:
        return env.WORKER_ALCHEMY_HTTP_POLYGON;
      case ChainId.Mainnet:
        return env.WORKER_ALCHEMY_HTTP_ETHEREUM;
      case NETWORK_BASE.chainId:
        return env.WORKER_ALCHEMY_HTTP_BASE;
    }
  } else {
    throw new Error('Invalid which value: ' + which);
  }*/

  // Shared URLs
  switch (chainId) {
    case ChainId.BSC:
      return 'https://lb.drpc.org/ogrpc?network=bsc&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_MODE.chainId:
      return 'https://lb.drpc.org/ogrpc?network=mode&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_POLYGONZKEVM.chainId:
      return 'https://lb.drpc.org/ogrpc?network=polygon-zkevm&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_MOONBEAM.chainId:
      return 'https://lb.drpc.org/ogrpc?network=moonbeam&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_BOBAETHEREUM.chainId:
      return 'https://lb.drpc.org/ogrpc?network=boba-eth&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_ZKSYNCERA.chainId:
      return 'https://lb.drpc.org/ogrpc?network=zksync&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_FILECOIN.chainId:
      return 'https://rpc.ankr.com/filecoin';
    case NETWORK_ROOTSTOCK.chainId:
      return 'https://lb.drpc.org/ogrpc?network=rootstock&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_SCROLL.chainId:
      return 'https://lb.drpc.org/ogrpc?network=scroll&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_BLAST.chainId:
      return 'https://lb.drpc.org/ogrpc?network=blast&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_LINEA.chainId:
      return 'https://lb.drpc.org/ogrpc?network=linea&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_GNOSIS.chainId:
      return 'https://lb.drpc.org/ogrpc?network=gnosis&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_METIS.chainId:
      return 'https://lb.drpc.org/ogrpc?network=metis&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_AVALANCHE.chainId:
      return 'https://lb.drpc.org/ogrpc?network=avalanche&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_MANTAPACIFIC.chainId:
      return 'https://lb.drpc.org/ogrpc?network=manta-pacific&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    //case NETWORK_TAIKO.chainId:
    //  return 'https://lb.drpc.org/ogrpc?network=taiko&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_ARBITRUM_SEPOLIA.chainId:
      return 'https://lb.drpc.org/ogrpc?network=arbitrum-sepolia&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_SEI.chainId:
      return 'https://evm-rpc.sei-apis.com';
    case NETWORK_MANTLE.chainId:
      return 'https://lb.drpc.org/ogrpc?network=mantle&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_LISK.chainId:
      return 'https://lb.drpc.org/ogrpc?network=lisk&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
    case NETWORK_GRAVITY.chainId:
      return 'https://rpc.ankr.com/gravity';
    case NETWORK_CORN.chainId:
      return 'https://maizenet-rpc.usecorn.com';
    case NETWORK_CELO.chainId:
      return 'https://forno.celo.org';
  }

  return undefined;
}

export function getSecondaryJsonRpcUrl(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): string | undefined {
  switch (chainId) {
    case ChainId.Arbitrum:
      return 'https://arbitrum-one.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case ChainId.Optimism:
      return 'https://optimism-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case ChainId.Polygon:
      return 'https://polygon-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case ChainId.Mainnet:
      return 'https://eth-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_BASE.chainId:
      return 'https://base-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case ChainId.BSC:
      return 'https://bsc-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_MODE.chainId:
      return 'https://mode-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_POLYGONZKEVM.chainId:
      return 'https://polygon-zkevm-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_MOONBEAM.chainId:
      return 'https://moonbeam.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_BOBAETHEREUM.chainId:
      return 'https://mainnet.boba.network';
    case NETWORK_ZKSYNCERA.chainId:
      return 'https://zksync-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_FILECOIN.chainId:
      return 'https://filecoin.chainup.net/rpc/v1';
    case NETWORK_ROOTSTOCK.chainId:
      return 'https://mycrypto.rsk.co';
    case NETWORK_SCROLL.chainId:
      return 'https://scroll-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_BLAST.chainId:
      return 'https://blastl2-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_LINEA.chainId:
      return 'https://linea-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_GNOSIS.chainId:
      return 'https://gnosis-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_METIS.chainId:
      return 'https://metis-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_AVALANCHE.chainId:
      return 'https://ava-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089/ext/bc/C/rpc';
    case NETWORK_MANTAPACIFIC.chainId:
      return 'https://pacific-rpc.manta.network/http';
    case NETWORK_TAIKO.chainId:
      return 'https://rpc.ankr.com/taiko';
    case NETWORK_ARBITRUM_SEPOLIA.chainId:
      return 'https://arbitrum-sepolia.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_SEI.chainId:
      return 'https://quiet-powerful-pond.sei-pacific.quiknode.pro/51ea2b327ce3f16aa6e5528b1a6b7cc9ec33e3d3/';
    case NETWORK_MANTLE.chainId:
      return 'https://mantle-mainnet.blastapi.io/9550f18a-2b54-48e5-a04f-c3fa4f9ce089';
    case NETWORK_LISK.chainId:
      return 'https://rpc.api.lisk.com';
    case NETWORK_GRAVITY.chainId:
      return 'https://rpc.gravity.xyz';
    case NETWORK_CORN.chainId:
      return 'https://mainnet.corn-rpc.com';
    case NETWORK_CELO.chainId:
      return 'https://lb.drpc.org/ogrpc?network=celo&dkey=ApAw2_gMi0NTmatto_u9y7FENPQ0gqkR7qTMxqxINsn1';
  }

  return undefined;
}

export function getTertiaryJsonRpcUrl(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): string | undefined {
  switch (chainId) {
    case ChainId.Arbitrum:
      return 'https://arb1.arbitrum.io/rpc';
    case ChainId.Optimism:
      return 'https://mainnet.optimism.io';
    case ChainId.Polygon:
      return 'https://polygon-rpc.com';
    case ChainId.Mainnet:
      return 'https://cloudflare-eth.com';
    case NETWORK_BASE.chainId:
      return 'https://mainnet.base.org';
    case ChainId.BSC:
      return 'https://bscrpc.com';
    case NETWORK_MODE.chainId:
      return 'https://mainnet.mode.network';
    case NETWORK_POLYGONZKEVM.chainId:
      return 'https://zkevm-rpc.com';
    case NETWORK_MOONBEAM.chainId:
      return 'https://rpc.api.moonbeam.network';
    case NETWORK_BOBAETHEREUM.chainId:
      return 'https://replica.boba.network';
    case NETWORK_ZKSYNCERA.chainId:
      return 'https://mainnet.era.zksync.io';
    case NETWORK_FILECOIN.chainId:
      return 'https://node.filutils.com/rpc/v1';
    case NETWORK_ROOTSTOCK.chainId:
      return 'https://public-node.rsk.co';
    case NETWORK_SCROLL.chainId:
      return 'https://rpc.scroll.io';
    case NETWORK_BLAST.chainId:
      return 'https://rpc.blast.io';
    case NETWORK_LINEA.chainId:
      return 'https://rpc.linea.build';
    case NETWORK_GNOSIS.chainId:
      return 'https://rpc.gnosischain.com';
    case NETWORK_METIS.chainId:
      return 'https://andromeda.metis.io/?owner=1088';
    case NETWORK_AVALANCHE.chainId:
      return 'https://api.avax.network/ext/bc/C/rpc';
    case NETWORK_MANTAPACIFIC.chainId:
      return 'https://1rpc.io/manta';
    case NETWORK_TAIKO.chainId:
      return 'https://rpc.taiko.xyz';
    case NETWORK_ARBITRUM_SEPOLIA.chainId:
      return 'https://sepolia-rollup.arbitrum.io/rpc';
    case NETWORK_MANTLE.chainId:
      return 'https://rpc.mantle.xyz';
    case NETWORK_CORN.chainId:
      return 'https://rpc.ankr.com/corn_maizenet';
    case NETWORK_CELO.chainId:
      return 'https://rpc.ankr.com/celo';
  }
}

export function getQuaternaryJsonRpcUrl(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): string | undefined {
  switch (chainId) {
    case NETWORK_TAIKO.chainId:
      return 'https://rpc.taiko.tools	';
  }

  return undefined;
}

export function getBlockchainProvider(
  chainId: ChainId,
  which: 'client' | 'server',
  env: any
): BaseProvider | undefined {
  let providers: BaseProvider[] = [];

  const network = NETWORKS.find((n) => n.chainId === chainId);
  if (!network) {
    return undefined;
  }

  const wsURL = getWebsocketUrl(chainId, which, env);
  if (wsURL) {
    providers.push(
      new StaticWebsocketProvider(wsURL, network, () => {
        console.log(network.name + ' websocket provider connected');
      })
    );
  }

  const primaryHttpURL = getPrimaryJsonRpcUrl(chainId, which, env);
  if (primaryHttpURL) {
    providers.push(
      new StaticJsonRpcProvider(
        {
          url: primaryHttpURL,
          skipFetchSetup: which === 'server',
        },
        which === 'server' ? network : undefined
      )
    );
  }

  const secondaryHttpURL = getSecondaryJsonRpcUrl(chainId, which, env);
  if (secondaryHttpURL) {
    providers.push(
      new StaticJsonRpcProvider(
        {
          url: secondaryHttpURL,
          skipFetchSetup: which === 'server',
        },
        which === 'server' ? network : undefined
      )
    );
  }

  const tertiaryHttpURL = getTertiaryJsonRpcUrl(chainId, which, env);
  if (tertiaryHttpURL) {
    providers.push(
      new StaticJsonRpcProvider(
        {
          url: tertiaryHttpURL,
          skipFetchSetup: which === 'server',
        },
        which === 'server' ? network : undefined
      )
    );
  }

  const quaternaryHttpURL = getQuaternaryJsonRpcUrl(chainId, which, env);
  if (quaternaryHttpURL) {
    providers.push(
      new StaticJsonRpcProvider(
        {
          url: quaternaryHttpURL,
          skipFetchSetup: which === 'server',
        },
        which === 'server' ? network : undefined
      )
    );
  }

  if (providers.length === 0) {
    return undefined;
  }

  if (providers.length === 1) {
    return providers[0];
  }

  const providerConfig = providers.map((p) => {
    return {
      provider: p,
      timeout: 2000,
    };
  });

  return new FallbackProvider(providerConfig);
}
